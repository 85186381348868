*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  border: none !important;
  outline: none !important;
}

html {
  font-size: 16px;
  min-height: 100%;
}

body {
  min-height: 100vh;
  background-color: rgba(101, 16, 17);
}

h2,
h3,
p {
  margin-bottom: 1em;
}

li {
  list-style: none;
}

a,
a:visited {
  text-decoration: none;
  color: inherit;
}

button {
  background-color: rgb(69, 126, 249);
  color: white;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5em 1.25em;
  cursor: pointer;
  border-radius: 0.3rem;
  border: none;
}

form {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  max-width: 100%;
  margin: 1rem 0;
}

form input {
  padding: 0.5rem;
  font-size: 1rem;
  font-family: inherit;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

/* Navbar */
.navbar {
  min-height: 3rem;
  display: flex;
  background-color: rgba(16, 16, 102) ;
}

.navbar_container {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;

  outline: 2px solid red;
}

.navbar_container > div + div {
  outline: 2px solid purple;
}

.navbar img {
  width: 10%;
  outline: 2px solid green;
}

.navbar a {
  font-family: 'Roboto', sans-serif;
  color: white;
}

header {
  min-width: 100%;
  background-color: white;
}

/* Container */
.container {
  width: min(90%, 45rem);
  margin: 2rem auto;
  /* min-height: 100%; */
  /* height: 300px; */
  /* outline: 2px solid blue; */
}

.container > h1 {
  outline: 2px solid red;
  font-family: 'Lato', sans-serif;
}

.container > h3 {
  font-family: 'Lato', sans-serif;
}

.container.large {
  /* width: min(90%, 72rem); */
  width: 90%;
}

.homePage_Text {
  color: rgb(235, 235, 235);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-rows: auto;
  gap: 0.3rem;
}

.card {
  border-radius: 0.3rem;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out forwards;
  position: relative;
}

.title {
  text-align: center;
}

.card img {
  min-width: 100%;
}

.card-name {
  opacity: 0;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(50%);
}

.card:hover .card-name {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hero__details-container {
  display: flex;
  gap: 2rem;
  /* flex-wrap: wrap; */
  line-height: 1.6;
  /* justify-content: space-evenly; */
  width: 100%;
  color: rgb(235, 235, 235);
}

.hero__details_img_container {
  outline: 2px solid purple;
  width: 50%;
  /* display: flex; */
  /* justify-content: center; */
}

.hero__details-container img {
  /* flex: 1; */
  /* max-width: 100%;
  max-height: 36rem; */
  border-radius: 1.5%;
  width: 80%;
  display: flex;
  margin: 0 auto;

}

.hero__details {
  /* flex: 1; */
  /* min-width: 35ch; */
  outline: 2px solid green;
  width: 50%;
}

.hero__details p {
  font-size: 20px;
  font-family: 'Lato', sans-serif;

}

.hero__details li {
  list-style: disc;
  margin-left: 1.5rem;
  font-family: 'Roboto', sans-serif;
}

.hero__details h4 {
  font-size: 24px;
  font-family: 'Lato';
}

.hero__series {
  /* outline: 2px solid green; */
}

.footer {
  text-align: center;
  margin-top: 15rem;
}

@media (max-width: 1050px) {
  .hero__details-container {
    display: flex;
    gap: 2rem;
    /* flex-wrap: wrap; */
    line-height: 1.6;
    /* justify-content: space-evenly; */
    width: 100%;
    flex-direction: column;
  }

  .hero__details_img_container {
    outline: 2px solid purple;
    width: 100%;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
  }
  
  .hero__details-container img {
    /* flex: 1; */
    /* max-width: 100%;
    max-height: 36rem; */
    border-radius: 1.5%;
    width: 60%;
    /* display: flex; */
    /* margin: 0 auto; */
    outline: 2px solid black;
  }

  .hero__details {
    /* flex: 1; */
    /* min-width: 35ch; */
    outline: 2px solid green;
    width: 100%;
  }
}

.searchForm_container {
  outline: 2px solid black;
  margin-top: 24px;
}